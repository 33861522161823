import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import profile from "../images/Vector.jpg";

import MessageInput from "../components/MessageInput/index";
import LoggedNav from "../components/LoggedNav";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { isMobile } from "react-device-detect";
import { RiArrowLeftSLine } from "react-icons/ri";
import { MessageList } from "../components/MessageList/index";



const SubscriptionMessageScreen = (props) => {
  const [content, setContent] = useState({});
  const [status, setStatus] = useState({});

  const { state } = useLocation();

  const navigate = useNavigate();
  // const { state } = useLocation();
  // const redirectToProfile = () => {
  //     localStorage.setItem("chef_id", content.booked_chef_id)
  //     navigate("/browse-chef")
  // }
  // const redirectToReview = () => {
  //     localStorage.setItem("from_msg", 1)
  //     navigate("/rate-chef", { state: { chef_name: content.booked_chef, chef_profile_image: content.booked_chef_profile_image, chef_id: content.booked_chef_id, booking_status: content.booking_status, booking_id: content.booking_id, } })
  // }
  // const redirectToTips = () => {
  //     localStorage.setItem("from_msg", 1)
  //     navigate("/tip-amount", { state: { chef_name: content.booked_chef, chef_profile_image: content.booked_chef_profile_image, booking_id: content.booking_id, booking_status: content.booking_status } })
  // }

  useEffect(() => {
    var title = props.customer
      ? "Message View | CHEF REPUBLIC"
      : "Message View | CHEF | CHEF REPUBLIC";
    var desc =
      "You can have one on one chat between customer and client which includes details of the order and any additional requests and instructions";
    const titleTag = document.querySelector("title");
    titleTag.innerText = title;
    const metaTitle = document.querySelector("meta[name='title']");
    metaTitle.setAttribute("content", title);
    const metaDescription = document.querySelector("meta[name='description']");
    metaDescription.setAttribute("content", desc);
    const metaTitleOG = document.querySelector("meta[property='og:title']");
    metaTitleOG.setAttribute("content", title);
    const metaDescriptionOG = document.querySelector(
      "meta[property='og:description']"
    );
    metaDescriptionOG.setAttribute("content", desc);
    const metaTitleTwitter = document.querySelector(
      "meta[property='twitter:title']"
    );
    metaTitleTwitter.setAttribute("content", title);
    const metaDescriptionTwitter = document.querySelector(
      "meta[property='twitter:description']"
    );
    metaDescriptionTwitter.setAttribute("content", desc);

 
    const { data } = state;

  
    setContent(data)
   
    setStatus(data.status);
    console.log(content);

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="container my-4">
        {isMobile ? null : (
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li
                className="breadcrumb-item active d-flex justify-content-center"
                style={{ cursor: "pointer" }}
                aria-current="page"
                onClick={() => {
                  navigate(-1);
                  // props.customer ? "/message-list" : "/chef/message-list"
                }}
              >
                <p>
                  <span className="pe-2 py-1 h4">
                    <RiArrowLeftSLine />
                  </span>
                  <span> Go Back</span>
                </p>
                {/* <span className="pe-2 h4">
                  <RiArrowLeftSLine />
                </span>
                Go Back */}
              </li>
            </ol>
          </nav>
        )}
        <div className="container d-flex justify-content-center align-items-center">
          <div
            className={isMobile ? "pb-3" : "card pb-3"}
            style={{
              width: isMobile ? "100%" : "400px",
              height: isMobile ? "100%" : "563px",
              borderRadius: "20px",
              boxShadow: isMobile ? "" : "0,0,0,grba(0,0,0,0.2)",
            }}
          >
            <div className="card-header border-0 bg-transparent">
              <div
                className="d-flex justify-content-evenly mx-auto py-3"
                key={"1"}
                style={{
                  width: isMobile ? "100%" : "99%",
                  height: "auto",
                  borderRadius: "10px",
                  boxShadow: "0,0,0,grba(0,0,0,0.2)",
                }}
              >
                <div className="col-3">
                  <img
                    src={content.image ? content.image : profile}
                    alt=""
                    style={{
                      height: "60px",
                      width: "60px",
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <div
                  className="col-8 d-flex flex-column justify-content-center"
                  style={{ paddingLeft: "0" }}
                >
                  <div className="flex-column">
                    <h6 className="fw-bold p-0 m-0">{content.name} </h6>
                    {/* <span
                      style={{
                        fontSize: "0.7rem",
                        color: "white",
                        background:
                          content.subscription_status === "Active"
                            ? "Green"
                            : "red",
                        borderRadius: "20px",
                        padding: "3px 15px",
                        fontWeight: 700,
                      }}
                    >
                      {content.subscription_status}
                    </span> */}
                    <div className="row my-2">
                      <div>
                        <p
                          className="badge text-wrap my-0 mx-1"
                          style={{
                            fontSize: "0.7rem",
                            color: "white",
                            background:
                              content.subscription_status === "Active"
                                ? "Green"
                                : "red",
                            borderRadius: "20px",
                            padding: "3px 15px",
                            fontWeight: 700,
                          }}
                        >
                          {content.subscription_status}
                        </p>
                        {content.plan_id && (
                          <p
                            className="badge text-wrap mt-1 mx-1"
                            style={{
                              fontSize: "11px",
                              fontWeight: 700,
                              color: "white",
                              minHeight: "15px",
                              borderRadius: "25px",
                              backgroundColor: content.color_code,
                            }}
                          >
                            {content.plan_name}

                            {}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="card-body"
              style={{
                height: isMobile ? "55vh" : "400px",
                overflowY: "scroll",
              }}
            >
              <MessageList
                roomId={`${content.chef_id}_${content.customer_id}`}
                customer={props.customer}
              />
            </div>
            <div className="card-footer border-0 bg-white">
              {content.subscription_status === "Inactive" ? (
                <p className="text-center">You Are Not Allowed to Message</p>
              ) : (
                <MessageInput
                  userId={
                    props.customer ? content.chef_id : content.customer_id
                  }
                  roomId={`${content.chef_id}_${content.customer_id}`}
                  customer={props.customer}
                  first_msg={content.first_msg ? content.first_msg : ""}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionMessageScreen;
