import React from "react";

function Desc() {
  return (
    <div
      id="customerDesc"
    >
      <h6
        style={{ color: "rgba(6, 40, 52, 1)", opacity: 1 }}
        className="text-center fw-light lh-lg"
      >
        Book a home chef to come to your home with fresh groceries and cook/meal prep healthy home made meals for you and your family for Upto 20 servings, catered to your preference and taste.
      </h6>
    </div>
  );
}

export default Desc;
